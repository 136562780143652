import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

import { SALES_CHANNELS_BY_ID_MAP } from '@sm/SalesChannels';
import FLAGS from '@sm/Flags';
import { UNKNOWN_PRODUCT } from '@sm/api';
import { isEmpty } from '@utils/types';
import { ExpandIcon } from '../../icons';
import NoImage from '../../icons/no-image-available.png';
import { FlatButton } from '../../buttons';
import { SafeImage } from '../../images';
import { WhiteTooltip } from '../../index';

const flagStyle = {
  width: 22,
  height: 12
};

const productCategoryClasses =
  'py-025 px-1 text-xxs inline-block font-bold mr-1 rounded-sm text-white';

const generateBrandImage = name => (
  <div className="font-medium text-black h-8 w-8 flex justify-center items-center text-base mr-2 flex-shrink-0 round bg-white-blue">
    <p>{name.substring(0, 2).toUpperCase()}</p>
  </div>
);

const getImage = (type, name, smallImage, imgClass, onImageClick) => {
  if (type === 'brand') {
    return generateBrandImage(name);
  }
  const imageUrl = smallImage || NoImage;
  const image = <SafeImage src={imageUrl} className="max-h-full max-w-full" />;
  const classes = classNames(imgClass, 'flex justify-center items-center flex-shrink-0');
  if (onImageClick)
    return (
      <FlatButton className={classes} onClick={onImageClick}>
        {image}
      </FlatButton>
    );
  return <div className={classes}>{image}</div>;
};

const TolTip = withStyles({ tooltip: { top: '0.5rem' } })(WhiteTooltip);

const ProductCard = props => {
  const {
    name,
    type,
    smallImage,
    asin,
    sku,
    productCount,
    marketplace,
    lastItem,
    customProperties,
    style,
    className,
    imageClassName,
    firstRowClassName,
    onClick,
    onImageClick,
    selected,
    showArrow,
    childrenTitle,
    parentBadge,
    childBadge,
    enableTooltips,
    thirdRowView,
    salesChannelId
  } = props;

  const selectedFlag = FLAGS.find(f => f.id === marketplace);
  const flagComponent = selectedFlag ? (
    <img src={selectedFlag.src} alt="flag" className="mr-2 rounded-sm" style={flagStyle} />
  ) : null;

  const variationsLine = !isEmpty(customProperties) ? (
    <div className="text-black text-xs mb-05 whitespace-no-wrap text-left">
      {Object.keys(customProperties).map(value => {
        if (!value || value === 'null') {
          return null;
        }
        return (
          <span key={value}>
            <span className="font-medium">{value}</span>
            <span className="mr-1">: {customProperties[value]}</span>
          </span>
        );
      })}
    </div>
  ) : (
    ''
  );

  const getChildRow = () => {
    return (
      <div className="flex flex-row items-center">
        <div className={`bg-gray py-05 ${productCategoryClasses}`}>{childBadge}</div>
        <p className="text-gray-dark text-xs">({asin})</p>
      </div>
    );
  };

  const getParentRow = () => {
    let image = null;
    if (selected !== null && showArrow) {
      image = <ExpandIcon selected={selected} className="h-3 w-3 ml-1 hidden sm:block" />;
    }

    const parentBadgeView = parentBadge ? (
      <div className={`bg-blue py-05 ${productCategoryClasses}`}>Parent</div>
    ) : null;

    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          {flagComponent}
          {parentBadgeView}
          <div className="text-gray-dark text-xs hidden sm:block">({asin})</div>
          <div className="text-gray-dark text-xs sm:hidden block">{asin}</div>
          {image}
        </div>
        <div className="text-gray-dark text-xs">
          {productCount} {childrenTitle}
        </div>
      </div>
    );
  };

  const getBrandRow = () => {
    return (
      <>
        <div className={`bg-gray-dark ${productCategoryClasses}`}>Brand</div>
        <p className="text-gray-dark text-xs truncate">Contains {productCount} products</p>
      </>
    );
  };

  const getSalesChannelIcon = () => {
    if (!salesChannelId) return null;
    const salesChannel = SALES_CHANNELS_BY_ID_MAP[salesChannelId];
    if (!salesChannel) return null;
    return <img src={salesChannel.short_icon} className="flex-shrink-0 h-4 mr-2" />;
  };

  const getDefaultRow = () => {
    return (
      <div className="flex items-center">
        {asin && getSalesChannelIcon()}
        {flagComponent}
        <p className="text-gray-dark text-xs truncate">{asin || null}</p>
      </div>
    );
  };

  const getSecondRow = () => {
    if (type === 'child') {
      return getChildRow();
    }
    if (type === 'parent') {
      return getParentRow();
    }
    if (type === 'brand') {
      return getBrandRow();
    }
    return getDefaultRow();
  };

  const getThirdRow = () => {
    if (thirdRowView) return thirdRowView;
    if (type === 'parent' || type === 'brand') return null;
    const rowContent = (
      <div className="flex flex-row items-center">
        {!asin && getSalesChannelIcon()}
        <div className="text-xs text-gray-dark truncate max-w-8/10 text-left">
          {sku ? `SKU: ${sku}` : null}
        </div>
      </div>
    );
    if (!enableTooltips || !sku) return rowContent;
    return (
      <div className="w-full flex justify-start">
        <TolTip arrow title={sku} placement="top">
          {rowContent}
        </TolTip>
      </div>
    );
  };

  const firstRowClassNameRoot = classNames(
    'font-medium text-black text-xs overflow-hidden max-w-full truncate whitespace-no-wrap',
    firstRowClassName
  );

  const getFirstRow = () => {
    if (type === 'child') return null;
    const rowContent = <p className={firstRowClassNameRoot}>{name || UNKNOWN_PRODUCT}</p>;
    if (!enableTooltips) return rowContent;
    return (
      <div className="w-full flex">
        <TolTip arrow title={name || UNKNOWN_PRODUCT} placement="top">
          {rowContent}
        </TolTip>
      </div>
    );
  };
  const firstRow = getFirstRow();
  const secondRow = <div className="flex items-center">{getSecondRow()}</div>;
  const thirdRow = getThirdRow();

  const imgClass = classNames('mr-2', {
    'h-8 w-8': !imageClassName,
    'w-11-5 sm-h-46px': imageClassName
  });

  const image = getImage(type, name, smallImage, imgClass, onImageClick);

  return (
    <div
      role="row"
      tabIndex="-1"
      style={style}
      className={className}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' && onClick) onClick();
      }}
    >
      <div className="flex items-center overflow-hidden h-full w-full mr-1">
        {image}
        <div className="overflow-hidden w-full truncate whitespace-no-wrap">
          {firstRow}
          {variationsLine}
          {secondRow}
          {thirdRow}
        </div>
      </div>
      {lastItem}
    </div>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string,
  smallImage: PropTypes.string,
  asin: PropTypes.string,
  sku: PropTypes.string,
  productCount: PropTypes.number,
  type: PropTypes.string,
  marketplace: PropTypes.number,
  lastItem: PropTypes.node,
  customProperties: PropTypes.instanceOf(Object),
  style: PropTypes.shape(),
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  firstRowClassName: PropTypes.string,
  onClick: PropTypes.func,
  onImageClick: PropTypes.func,
  selected: PropTypes.bool,
  showArrow: PropTypes.bool,
  childrenTitle: PropTypes.string,
  parentBadge: PropTypes.bool,
  childBadge: PropTypes.string,
  enableTooltips: PropTypes.bool,
  thirdRowView: PropTypes.node,
  salesChannelId: PropTypes.number
};

ProductCard.defaultProps = {
  name: '',
  asin: '',
  sku: '',
  lastItem: '',
  customProperties: {},
  style: {},
  className: '',
  imageClassName: '',
  firstRowClassName: '',
  marketplace: 0,
  smallImage: '',
  productCount: 0,
  type: '',
  onClick: null,
  onImageClick: null,
  selected: null,
  showArrow: false,
  childrenTitle: 'variations',
  parentBadge: true,
  childBadge: 'Child',
  enableTooltips: false,
  thirdRowView: null,
  salesChannelId: null
};

export default ProductCard;
