import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { ImageObserver } from 'seller/containers/observer';
import ROUTES from 'seller/routing';
import SearchIcon from '@components/icons/ic_search.svg';
import { isSmallSize } from '@sm/WindowSizes';
import { axiosCall } from '@utils';
import API from '@sm/api';
import { LoadingSpinner, WebImg } from '@components';
import { BackIconButton, RoundedButton, TextFieldIcon } from '@componentsV2';
import HomepageFooter from './HomepageFooter';
import { LEARN_MORE_POSTS } from './learnMorePosts';
import LearnMoreCard from './LearnMoreCard';
import HeaderLayout from './HeaderLayout';

function LearnMorePage({ history, location }) {
  const postIdUrlParameter = new URLSearchParams(location.search).get('postId');
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pulledPosts, setPulledPosts] = useState(null);
  const scrollContainerRef = useRef();

  const posts = [...LEARN_MORE_POSTS, ...(pulledPosts || [])];
  const filteredPosts = posts.filter(post => {
    return post.title.toLowerCase().includes(searchValue.toLowerCase());
  });

  const pullPosts = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosCall.get(API.cms.learn_more.posts);
      setPulledPosts(data);
    } catch (error) {
      setPulledPosts([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (pulledPosts === null && !isLoading) {
      pullPosts();
    }
  }, [isLoading, pullPosts, pulledPosts]);

  const getContent = () => {
    const selectedPostData = posts.find(post => String(post.id) === postIdUrlParameter);
    const getTitleImg = () => {
      const classes = 'object-cover rounded-lg h-full w-full';
      if (typeof selectedPostData.title_image === 'string') {
        return (
          <img
            className={classes}
            src={selectedPostData.title_image}
            alt={selectedPostData.title}
          />
        );
      }
      return (
        <WebImg className={classes} {...selectedPostData.title_image} imgClassName={classes} />
      );
    };

    const getContentImg = (imgData, className = 'object-cover rounded-lg h-full w-full') => {
      if (typeof imgData === 'string') {
        return <img className={className} src={imgData.content_image} />;
      }
      return <WebImg className={className} {...imgData} imgClassName={className} />;
    };

    if (postIdUrlParameter && !selectedPostData) {
      return <LoadingSpinner />;
    }

    if (selectedPostData) {
      return (
        <div>
          <div className="w-full flex flex-col items-center max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px pt-6 xl:pt-10 px-6 xl:px-0">
            <Link to={ROUTES.learnMore} className="self-start">
              <BackIconButton />
            </Link>
            <div className="font_24px xl:font_50px font-bold text-center mt-8">
              {selectedPostData.title}
            </div>
            <div className="text-lg xl:text-2xl font-light flex flex-col items-center pb-8 xl:pb-12">
              <div className="my-8 relative" style={{ height: isSmallSize() ? '150px' : '500px' }}>
                <ImageObserver className="h-full w-full">{getTitleImg()}</ImageObserver>
              </div>
              {selectedPostData.header && (
                <div className="self-start font-bold text-xl xl:text-2xl mb-4">
                  {selectedPostData.header}
                </div>
              )}
              <div className="mb-5 self-start">{selectedPostData.intro}</div>
              <div className="flex flex-col gap-5">
                {selectedPostData.points.map((point, index) => {
                  return (
                    <div>
                      <div className="text-xl xl:text-3xl font-bold mb-4">
                        <span>{index + 1}. </span>
                        {point.title}
                      </div>
                      {point.picture && (
                        <div className="pl-5 my-4 relative">
                          <ImageObserver className="h-full w-1/2">
                            {getContentImg(point.picture, 'w-full')}
                          </ImageObserver>
                        </div>
                      )}
                      <div className="pl-5 flex flex-col gap-4">
                        {point.header && <div className="font-bold">{point.header}</div>}
                        {point.points && (
                          <div className="pl-5 flex flex-col gap-2">
                            {point.points.map(pointItem => (
                              <div>
                                <span className="h-2 w-2 mb-05 mr-2 rounded-full bg-gray-black inline-block" />
                                {pointItem?.content || pointItem}
                                {pointItem.points && (
                                  <div className="pl-5 my-2 flex flex-col gap-2">
                                    {pointItem.points.map(pointItem2 => (
                                      <div>
                                        <span className="h-2 w-2 mb-05 mr-2 rounded-full bg-gray-dark inline-block" />
                                        {pointItem2}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        <div>{point.content}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="hidden my-8 relative"
                style={{ height: isSmallSize() ? '120px' : '300px' }}
              >
                <ImageObserver className="h-full w-full">
                  {getContentImg(selectedPostData.content_image)}
                </ImageObserver>
              </div>
              <div className="self-start text-2xl font-bold my-4">
                {selectedPostData.summary_header}
              </div>
              <div>{selectedPostData.summary}</div>
              <div className="my-5 font-bold text-center">{selectedPostData.cta}</div>
              <Link to={ROUTES.registration}>
                <RoundedButton text="Start Free 30 Days Trial" />
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center w-full xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px px-6 xl:px-0 overflow-auto flex-grow flex-shrink-0">
        <div className="flex items-center pt-8 text-center">
          <div className="font-bold font_50px">
            <span>Stay </span>
            <span className="playfair_display italic">Curious</span>
          </div>
        </div>
        <p className="font-light text-xl mt-5 text-center">
          Stay curious, stay informed. Dive into insights and strategies that help Amazon Sellers
          succeed and master their numbers.
        </p>
        <div className="mt-8 my-4 flex justify-center w-96">
          <TextFieldIcon
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className="w-full"
            label="Search article or write keyword..."
            endIcon={<img src={SearchIcon} alt="search" />}
          />
        </div>
        <div className="pb-12 pt-4 grid grid-cols-2 xl:grid-cols-4 gap-4 xl:gap-6">
          {filteredPosts.map(post => (
            <LearnMoreCard
              key={post.id}
              image={post.preview_image}
              title={post.title}
              onClick={() => {
                history.push(`${ROUTES.learnMore}?postId=${post.id}`);
              }}
            />
          ))}
        </div>
        {!filteredPosts.length && (
          <div className="text-gray-dark text-center mb-16 mt-4">
            Sorry, no results found. Please try a different keyword.
          </div>
        )}
        {isLoading && !searchValue && (
          <div className="flex items-center justify-center">
            <LoadingSpinner
              override
              className="flex items-center justify-center h-64 pointer-events-none"
            />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (postIdUrlParameter && scrollContainerRef?.current) {
      scrollContainerRef?.current.scrollTo({ top: 0 });
    }
  }, [postIdUrlParameter, scrollContainerRef]);

  return (
    <HeaderLayout scrollContainerRef={scrollContainerRef}>
      <ImageObserver
        className="h-full w-full flex flex-col items-center justify-between"
        loadingClassname="overflow-hidden absolute w-screen h-screen"
      >
        {getContent()}
        <HomepageFooter />
      </ImageObserver>
    </HeaderLayout>
  );
}

LearnMorePage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(LearnMorePage);
