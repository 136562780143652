import {
  getPostContentPictureByPostId,
  getPostPreviewImageByPostId,
  getPostTitlePictureByPostId
} from './img/learn-more-posts';

export const LEARN_MORE_POSTS = [
  {
    id: 'l-4',
    preview_image: getPostPreviewImageByPostId(4),
    title: 'Top 10 Essential Tools for Amazon Sellers',
    title_image: getPostContentPictureByPostId('sellermetrix'),
    intro:
      "Selling successfully on Amazon requires utilizing the right tools to manage sales analytics, advertising, keyword research, accounting, and order management. Here's a curated list of the top 10 Amazon seller tools, each excelling in their respective categories:",
    points: [
      {
        title: 'SellerMetrix',
        header: 'Best for Simple and Powerful Sales Analytics',
        content:
          'SellerMetrix provides an intuitive, streamlined approach to analyzing your Amazon sales, tracking your sales performance, profitability, and product-level insights effortlessly. It also features a powerful email tool to help sellers reengage and follow up with customers, boosting repeat sales and improving customer satisfaction. Additionally, SellerMetrix includes an advanced Search Query tool that enables sellers to see their share of traffic for each ASIN and identify which keywords drive conversions.'
      },
      {
        title: 'Helium 10',
        header: 'Best for Product Research and Market Intelligence',
        content:
          "Helium 10 is renowned for its extensive toolkit, especially for robust product research through its Black Box (product discovery) and Cerebro (reverse ASIN lookups). It's a valuable resource for sellers aiming to uncover profitable products and gain market insights."
      },
      {
        title: 'SellerApp',
        header: 'Best for Optimizing PPC Campaigns and Keyword Research',
        content:
          'SellerApp specializes in PPC management and keyword optimization, offering in-depth keyword insights, campaign analysis, and actionable recommendations. Its platform helps sellers significantly reduce advertising costs and enhance campaign effectiveness.'
      },
      {
        title: 'QuickBooks',
        header: 'Best for Small Business Accounting',
        content:
          'QuickBooks simplifies accounting for Amazon sellers, integrating seamlessly with your Amazon account. It provides accurate financial tracking, expense management, and tax preparation, helping sellers maintain strong financial health.'
      },
      {
        title: 'InventoryLab',
        header: 'Best for Streamlined Inventory Management',
        content:
          'InventoryLab helps Amazon sellers effectively manage inventory, orders, and listing creation. With its user-friendly dashboard and detailed profitability insights, InventoryLab is ideal for maintaining balanced inventory levels and efficient order processing.'
      },
      {
        title: 'FeedbackWhiz',
        header: 'Best for Enhancing Customer Communication and Reviews',
        content:
          'FeedbackWhiz streamlines customer follow-up emails, review monitoring, and reputation management. This tool helps sellers improve customer satisfaction, increase positive reviews, and proactively address negative feedback.'
      },
      {
        title: 'Teikametrics',
        header: 'Best for Advanced Advertising Optimization',
        content:
          'Teikametrics uses AI-driven technology to optimize Amazon PPC campaigns, enhancing ad spend efficiency and increasing profitability. Its automated adjustments and in-depth analytics are ideal for high-volume sellers looking to scale.'
      },
      {
        title: 'ShipStation',
        header: 'Best for Efficient Order Fulfillment and Shipping',
        content:
          'ShipStation simplifies shipping logistics by consolidating orders across multiple sales channels. Its powerful automation, discounted shipping rates, and streamlined workflows greatly improve order fulfillment efficiency.'
      },
      {
        title: 'TaxJar',
        header: 'Best for Managing Sales Tax Compliance',
        content:
          'TaxJar automatically calculates, reports, and files sales tax for Amazon sellers, significantly reducing the stress of tax compliance. Its accuracy and ease of use make managing sales tax obligations effortless.'
      },
      {
        title: 'Jungle Scout',
        header: 'Best for In-Depth Market Analytics',
        content:
          'Jungle Scout offers comprehensive market analytics, competitive tracking, and detailed sales estimates. Its data helps sellers understand market dynamics and competitor strategies effectively.'
      }
    ],
    content_image: getPostContentPictureByPostId(4),
    summary_header: 'Conclusion',
    summary:
      "Equipping yourself with the right tools can make or break your Amazon selling success. Whether you're enhancing profitability, optimizing ad spend, or simplifying operational tasks, these tools will empower you to grow your Amazon business strategically.",
    cta: 'Ready to simplify your sales analytics? Try SellerMetrix for 30 Days — free!'
  },
  {
    id: 'l-1',
    preview_image: getPostPreviewImageByPostId('ppc'),
    title: 'Mastering Amazon PPC: Best Practices for Optimizing Ad Spend',
    title_image: getPostContentPictureByPostId('ppc'),
    intro:
      "Amazon PPC (Pay-Per-Click) campaigns are essential for driving traffic, increasing visibility, and growing sales on Amazon's competitive marketplace. To effectively master Amazon PPC, sellers must deeply understand the elements driving success and strategically optimize their ad spend.",
    points: [
      {
        title: 'Start with the Right Keywords',
        header: 'Keywords form the cornerstone of every successful PPC campaign:',
        points: [
          "Comprehensive Keyword Research: Identify keywords matching your product niche and buyer intent. Utilize tools like Amazon's Search Term Report, SellerMetrix’s advanced keyword insights, or keyword planners to discover high-performing keywords. Focus on identifying long-tail keywords, which often have lower competition and higher conversion rates.",
          "Competitor Analysis: Analyze competitors' successful keywords using reverse ASIN lookup tools to uncover new keyword opportunities.",
          {
            content: 'Match Type Management: Categorize your keywords effectively into:',
            points: [
              'Broad Match: Reaches a wider audience but requires careful monitoring to control ad spend.',
              'Phrase Match: Offers more targeted exposure with manageable costs.',
              'Exact Match: Precisely targets highly relevant searches, maximizing conversion efficiency.'
            ]
          }
        ],
        content:
          'Proper keyword management ensures targeted ad placements and controlled spending, preventing unnecessary expenditure on irrelevant searches.'
      },
      {
        title: 'Optimize Bids and Budgets',
        header:
          'Strategic management of your bids and budgets significantly affects profitability:',
        points: [
          'Goal-Oriented Budgeting: Set daily budgets aligned with specific business objectives—such as increasing overall sales, improving profit margins, or enhancing brand visibility.',
          'Dynamic Bid Adjustment: Analyze conversion rates regularly to adjust bids effectively. Reduce bids or pause keywords that do not convert to conserve budget, and increase investment in high-performing keywords.',
          'Bid Automation: Consider using automation tools to manage bids based on real-time data, ensuring optimal bid levels and freeing time for strategic planning.'
        ]
      },
      {
        title: 'Track and Analyze Performance',
        header: 'Continuous monitoring and analytics are vital for campaign success:',
        points: [
          {
            content: 'Monitor Key Metrics:',
            points: [
              'Click-Through Rate (CTR): Indicates ad relevance and appeal. Aim for a high CTR to improve ad quality scores.',
              'Cost per Click (CPC): Helps manage cost efficiency; maintaining a lower CPC contributes to increased profitability.',
              'Advertising Cost of Sales (ACoS): Reflects overall campaign profitability. Regularly optimize for a balanced ACoS that aligns with profitability goals.',
              'Conversion Rate: Measures the effectiveness of your ads at turning clicks into sales, guiding strategic improvements.'
            ]
          },
          'Use Advanced Analytics: Leverage real-time analytics from platforms like SellerMetrix for rapid insights into campaign performance. Quickly identify and pause underperforming keywords, reallocating funds toward profitable keywords.'
        ]
      },
      {
        title: 'Enhance Ad Creatives and Listings',
        header:
          'Optimizing your product listings and ad creatives can significantly boost PPC results:',
        points: [
          'High-Quality Product Listings: Ensure your listings are well-optimized with clear, high-resolution images, compelling titles, informative bullet points, and accurate product descriptions.',
          'Ad Copy Testing: Regularly test variations in your ad copy to determine what resonates best with your audience. Small changes can significantly impact performance metrics.',
          'A/B Testing: Conduct regular A/B testing on your listings and ad creative elements (such as headlines, images, or descriptions) to identify the most effective messaging.'
        ]
      },
      {
        title: 'Continuous Testing and Scaling',
        header: 'Optimization is an ongoing process:',
        points: [
          'Regularly experiment with new keywords, bidding strategies, ad formats, and creative content.',
          'Gradually scale successful campaigns, increasing investment based on consistent performance metrics and data-driven insights.'
        ]
      },

      {
        title: 'Utilize Negative Keywords',
        header: 'Employ negative keywords effectively to exclude irrelevant traffic:',
        points: [
          'Identify and Implement: Continuously review search terms reports to discover irrelevant or costly terms. Implement these as negative keywords to prevent wasted spend.',
          'Regular Refinement: Frequently update your negative keyword lists to refine ad targeting and reduce inefficient spend.'
        ]
      }
    ],
    content_image: getPostContentPictureByPostId('ppc'),
    summary_header: 'Conclusion',
    summary:
      "Mastering Amazon PPC involves methodical keyword selection, vigilant bid and budget management, diligent performance analysis, effective listing optimization, and continual refinement of ad creatives and strategies. By embracing these best practices, you'll achieve sustained traffic growth, higher conversion rates, and significantly improved profitability.",
    cta: 'Want to master your Amazon PPC strategy? Try SellerMetrix for 30 Days — free!'
  },
  {
    id: 'l-2',
    preview_image: getPostPreviewImageByPostId('pnl'),
    title: 'The Ultimate Guide to Tracking and Improving Profit Margins on Amazon',
    title_image: getPostPreviewImageByPostId('pnl'),
    header: 'Easy 3-Step Guide to Tracking and Improving Profit Margins on Amazon',
    intro:
      'Understanding and improving your profit margins is essential for building a sustainable Amazon business. By accurately tracking expenses, you can identify opportunities to cut costs and maximize profitability. Follow these simple steps to effectively manage and enhance your profit margins.',
    points: [
      {
        title: 'Identify All Costs Involved',
        header: 'Clearly understanding all associated costs is the first critical step:',
        points: [
          'Amazon Fees: Account for referral fees, FBA fees, subscription fees, and storage costs.',
          'Shipping and Inventory Costs: Include all inbound shipping, product sourcing, packaging, and labeling expenses.',
          'Advertising Expenses: Track costs from PPC campaigns, promotional discounts, and marketing efforts.',
          'Operational Costs: Don’t overlook overhead expenses like software subscriptions, payroll, utilities, and other administrative costs.'
        ],
        content:
          'Fully understanding these expenses provides a transparent view of your profit margins and highlights areas to potentially reduce costs.'
      },
      {
        title: 'Leverage the Profit & Loss (P&L) Statement',
        header:
          'Utilizing a detailed Profit & Loss statement regularly is essential for tracking your financial health:',
        points: [
          'Regular Updates: Update your P&L statement frequently (weekly or monthly) to ensure accuracy and relevance.',
          'Identify Trends: Use your P&L to detect patterns in costs, revenue fluctuations, or seasonal changes, enabling proactive decision-making.',
          'Make Informed Decisions: Utilize insights gained from your P&L to make strategic decisions on pricing, inventory management, and targeted marketing efforts.'
        ],
        content:
          'A consistently maintained P&L empowers you to make strategic decisions to drive profitability.'
      },
      {
        title: 'Optimize Advertising Spend',
        header: 'Advertising can significantly impact your profit margins if managed improperly:',
        points: [
          'Evaluate PPC Campaigns: Regularly assess PPC campaigns to identify high-performing and low-performing ads.',
          'Focus on High ROAS: Redirect advertising budgets toward campaigns delivering the highest Return on Ad Spend (ROAS).',
          'Eliminate Wasteful Spend: Reduce or eliminate campaigns and keywords that consistently underperform.'
        ],
        content: 'Strategic advertising optimization directly improves your overall profitability.'
      }
    ],
    summary_header: 'Conclusion',
    summary:
      "Effectively tracking and enhancing your profit margins requires detailed expense monitoring, regular financial analysis, and strategic advertising optimization. With these focused efforts, you'll significantly improve your profitability and sustainably grow your Amazon business.",
    cta: 'Gain a complete view of your profit & loss with SellerMetrix! Free 30-Day Trial!'
  },
  {
    id: 'l-3',
    preview_image: getPostPreviewImageByPostId('buy_box'),
    title: 'Winning the Buy Box: Proven Strategies for Maximum Visibility',
    title_image: getPostPreviewImageByPostId('buy_box'),
    intro:
      'Winning the Buy Box on Amazon is crucial for maximizing sales and growing your business. Over 80% of Amazon sales occur through the Buy Box, making it a critical component for success.',
    points: [
      {
        title:
          'The Buy Box consists of several key components that together create a user-friendly interface designed to attract customers:',
        points: [
          'Buy New: Enables customers to purchase a brand-new version of the product.',
          'Buy Used: Provides customers the option to buy a previously owned product at a reduced cost, when available.',
          'Add to List: Allows customers to save the product to their wish list for future reference or purchase.',
          'Other Sellers on Amazon: Highlights alternative purchasing options offered by other reputable sellers.'
        ],
        content:
          'For Amazon sellers, the Buy Box serves as a critical initial touchpoint with potential buyers. Understanding how it functions and why it matters should be a primary focus area for sellers aiming to maximize visibility and sales.'
      },
      {
        title: 'Price Competitively',
        header: 'Pricing is a major determinant in winning the Buy Box:',
        points: [
          'Monitor Competitor Prices: Regularly track competitor pricing to ensure your products remain competitive. Utilize dynamic pricing tools to automatically adjust your prices in real-time based on competitor changes.',
          'Consider Automated Pricing Tools: Tools such as SellerMetrix can help you dynamically adjust prices efficiently, maximizing your opportunities to win the Buy Box without compromising margins.'
        ]
      },
      {
        title: 'Keep Inventory Levels High',
        header: 'Consistent inventory availability greatly influences Buy Box eligibility:',
        points: [
          'Manage Inventory Effectively: Maintain healthy stock levels to avoid running out of inventory. Frequent stockouts can negatively affect your Buy Box ranking.',
          "Utilize Fulfillment by Amazon (FBA): Leveraging FBA enhances your inventory management efficiency, improves customer delivery speed, and increases your eligibility for the Buy Box due to Amazon's preference for reliable fulfillment."
        ]
      },
      {
        title: 'Maintain High Seller Performance Metrics',
        header: 'Seller metrics significantly impact Buy Box positioning:',
        points: [
          'Order Defect Rate (ODR): Aim for an exceptionally low defect rate by addressing customer issues promptly and effectively.',
          'Late Shipment Rate: Ensure timely shipment to maintain a consistently low late shipment rate.',
          'Positive Customer Feedback: Encourage positive customer reviews by providing outstanding customer service and swiftly addressing any issues.'
        ],
        content: 'Consistent excellence in these metrics strongly boosts your Buy Box eligibility.'
      },
      {
        title: 'Improve Shipping Performance',
        header: 'Amazon prioritizes sellers offering fast and reliable shipping:',
        points: [
          'Fast Shipping Options: Provide expedited shipping methods to enhance customer satisfaction and Amazon’s perception of reliability.',
          'Shipping Accuracy: Minimize errors in fulfillment to avoid negative feedback and maintain strong shipping metrics.'
        ]
      },
      {
        title: 'Optimize Product Listings',
        header:
          'Optimizing your product listings enhances customer engagement and increases conversion rates:',
        points: [
          'Detailed Product Information: Provide comprehensive and accurate product details, compelling titles, and relevant keywords.',
          'Quality Images and Videos: High-resolution visuals significantly improve customer trust and increase conversion likelihood.'
        ]
      },
      {
        title: 'Leverage Amazon Advertising',
        header:
          'Utilizing strategic Amazon PPC advertising can indirectly support Buy Box success:',
        points: [
          'Boost Product Visibility: Strategic PPC campaigns can increase product visibility, sales velocity, and overall sales performance—factors considered by Amazon for Buy Box ranking.',
          'Monitor Ad Performance: Continuously track PPC performance to optimize ads that contribute positively to overall sales velocity.'
        ]
      },
      {
        title: 'Regularly Analyze Competitor Performance',
        header: 'Stay ahead by regularly reviewing competitor performance:',
        points: [
          "Track Competitor Behavior: Monitor competitors' pricing, stock levels, and fulfillment methods regularly.",
          'Adapt Strategically: Quickly adapt your strategies based on competitor insights to maintain Buy Box competitiveness.'
        ]
      }
    ],
    summary_header: 'Conclusion',
    summary:
      'Winning the Buy Box requires a multifaceted approach involving competitive pricing, strong inventory management, impeccable seller performance metrics, optimized listings, and effective advertising. Prioritizing these strategies ensures higher visibility and sustained growth on Amazon.',
    cta:
      'Ready to boost your Buy Box win rate? SellerMetrix can help! Try it free for 30 Days and gain insights to maximize your visibility.'
  }
];
