/* eslint-disable no-param-reassign */
import { cancelled, select } from '@redux-saga/core/effects';
import { put, takeLatest, takeLeading } from 'redux-saga/effects';

import { axiosCall, getCancelToken, listToStr } from '@utils';
import API, { LONG_REQUEST_TIMEOUT, rangeToAPI } from '@sm/api';
import { setNotificationMsgAction, setSelectedProducts } from 'seller/store/actions';
import { pnlDashboardSelector } from 'seller/store/reducers';
import { buildParams } from 'seller/utils/buildParams';
import {
  PULL_PNL_DASHBOARD_BRANDS,
  PULL_PNL_DASHBOARD_TABLE_DATA,
  PULL_PNL_TOP_SALES_PRODUCTS,
  setPnlDashboardBrandsAction,
  setPnlDashboardTableDataAction,
  setPnlDashboardTableLoadingAction
} from './index';

const addRecordIds = (objs, recordId = 0, recursionLevel = 0, parentsData = []) => {
  // For correct work of the DataTable component, need to add unique recordId to each object
  objs.forEach(obj => {
    obj.recordId = recordId;
    obj.level = recursionLevel;
    recordId += 1;
    obj.parentsData = parentsData;
    if (obj.children) {
      recordId = addRecordIds(obj.children, recordId, recursionLevel + 1, [
        ...parentsData,
        obj.metadata
      ]);
      recordId += obj.children.length;
    }
  });
  return recordId;
};

export const MAX_PRODUCTS_COUNT = 30;
export const TOP_PRODUCTS_COUNT = 10;
const DEFAULT_METRIC_TO_SORT_PRODUCTS = 'product_sales';

function* pullTableDataSaga() {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    yield put(setPnlDashboardTableLoadingAction(true));
    // Need to wait 1 second before starting the request
    yield new Promise(resolve => setTimeout(resolve, 1000));
    const store = yield select();
    const {
      segmentation,
      timeGrain,
      dateRange,
      granularity,
      selectedBrands,
      platforms
    } = yield select(pnlDashboardSelector);
    const params = {
      segmentation: listToStr(segmentation.filter(item => !item.disabled).map(item => item.key)),
      sales_channels: listToStr(platforms),
      brands: listToStr(selectedBrands),
      metrics: [],
      time_grain: timeGrain.toLowerCase(),
      granularity,
      ignore_cache: sessionStorage.getItem('ignore_cache') === 'true',
      ...rangeToAPI(dateRange)
    };
    const selectedProductsParams = buildParams({ state: store, dispatch: put }, false);
    if (selectedProductsParams.temp_bookmark_expired) return;

    const isNoProductsFilter =
      !selectedProductsParams.productIds &&
      !selectedProductsParams.parentIds &&
      !selectedProductsParams.brandIds &&
      !selectedProductsParams.bookmark_id;

    if (isNoProductsFilter && params.segmentation.includes('product')) {
      params.top_n = TOP_PRODUCTS_COUNT;
      params.top_metric = DEFAULT_METRIC_TO_SORT_PRODUCTS;
    }
    ['bookmark_id', 'brandIds', 'productIds', 'parentIds'].forEach(key => {
      if (selectedProductsParams[key]) params[key] = selectedProductsParams[key];
    });
    const { data } = yield axiosCall.get(API.pnl_dashboard.metrics, {
      params,
      cancelToken,
      timeout: LONG_REQUEST_TIMEOUT
    });
    addRecordIds(data.data);
    yield put(setPnlDashboardTableDataAction(data));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  } finally {
    yield put(setPnlDashboardTableLoadingAction(false));
    if (yield cancelled()) cancelRequest();
  }
}

function* pullBrandsSaga() {
  try {
    const { data } = yield axiosCall.get(API.agency_clients);
    yield put(setPnlDashboardBrandsAction(data));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  }
}

function* pullTopSalesProducts() {
  try {
    yield put(setPnlDashboardTableLoadingAction(true));
    const { dateRange, platforms } = yield select(pnlDashboardSelector);
    const params = {
      ...rangeToAPI(dateRange),
      top_n: TOP_PRODUCTS_COUNT,
      sales_channels: listToStr(platforms)
    };
    const { data } = yield axiosCall.get(API.pnl_dashboard.top_sales_products, { params });
    const preparedProducts = Object.entries(data).map(([productId, productData]) => ({
      id: productId,
      name: productData.name || productData.product_title,
      smallImage: productData?.smallimage || productData.img,
      marketplace: productData?.marketplace_id || null,
      asin: productData.asin,
      sku: productData.seller_sku,
      variation_value_one: productData.variation_value_one || productData.variant,
      variation_type_one: productData.variant ? 'variant' : productData.variation_type_one,
      type: productData?.variation_type_one || productData.variant ? 'variation' : 'default',
      selected: true,
      salesChannelId: productData?.sales_channel,
      ...productData
    }));
    yield put(setSelectedProducts(preparedProducts));
  } catch (error) {
    yield put(
      setNotificationMsgAction(
        'Pull data failed. Please contact support@sellermetrix.com if problem persists.'
      )
    );
  }
}

export function* pnlDashboardSagaWatcher() {
  yield takeLatest(PULL_PNL_DASHBOARD_TABLE_DATA, pullTableDataSaga);
  yield takeLeading(PULL_PNL_TOP_SALES_PRODUCTS, pullTopSalesProducts);
  yield takeLatest(PULL_PNL_DASHBOARD_BRANDS, pullBrandsSaga);
}
