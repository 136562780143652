/* eslint-disable global-require */

const IMAGES = {
  post_1: require('./post_1.png'),
  post_1_webp: require('./post_1.webp'),
  post_2: require('./post_2.png'),
  post_2_webp: require('./post_2.webp'),
  post_3: require('./post_3.png'),
  post_3_webp: require('./post_3.webp'),
  post_4: require('./post_4.png'),
  post_4_webp: require('./post_4.webp'),
  post_ppc: require('./ppc_preview.jpg'),
  post_pnl: require('./pnl.jpg'),
  post_buy_box: require('./buybox_amz.png'),
  content_picture_1: require('./content_picture_1.png'),
  content_picture_2: require('./content_picture_2.jpeg'),
  content_picture_3: require('./content_picture_3.jpeg'),
  content_picture_4: require('./content_picture_4.jpg'),
  content_picture_ppc: require('./ppc_content.jpg'),
  content_picture_sellermetrix: require('./sellermetrix.png'),
  title_picture_1: require('./title_picture_1.png'),
  title_picture_2: require('./title_picture_2.jpg'),
  title_picture_3: require('./title_picture_3.jpeg'),
  title_picture_4: require('./title_picture_4.jpg')
};

export const getPostPreviewImageByPostId = id => ({
  src: IMAGES[`post_${id}`],
  srcWebp: IMAGES[`post_${id}_webp`]
});

export const getPostTitlePictureByPostId = id => ({
  src: IMAGES[`title_picture_${id}`]
});

export const getPostContentPictureByPostId = id => ({
  src: IMAGES[`content_picture_${id}`]
});
